<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Bienvenido a Whatscupon! 👋🏻
                  </p>
                  <p class="mb-2">
                    <!-- Please sign-in to your account and start the adventure -->
                    Ingresa con tu cuenta
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="loginUser">
                    <v-text-field
                      v-model="form.username"
                      outlined
                      label="Email o usuario"
                      placeholder="Email o usuario"
                      hide-details="auto"
                      class="mb-6"
                      :rules="nameRules"
                      @keyup="loginError = null"
                      lazy-validation
                    ></v-text-field>

                    <v-text-field
                      v-model="form.password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Contraseña"
                      placeholder="Contraseña"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      :rules="passwordRules"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                      @keyup="loginError = null"
                    ></v-text-field>


                    <div v-if="loginError" class="mt-4" style="color: #ff4c51">{{ loginError }}</div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :disabled="loging"
                    >
                      Ingresar
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <!-- <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">
                    ¿Eres nuevo en la plataforma?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Create an account
                  </router-link>
                </v-card-text> -->

                <!-- divider -->
                <v-card-text class="d-flex align-center mt-2" v-if="false">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text>

                <!-- social links -->
                <v-card-actions class="d-flex justify-center" v-if="false">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon
                      :color="$vuetify.theme.dark ? link.colorInDark:link.color"
                    >
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from 'axios'

export default {
  data: () => {
    return {
      form: {
        username: '',
        password: '',
      },
      remember_me: false,
      nameRules: [
        v => !!v || 'El email o usuario es obligatorio',
      ],
      passwordRules: [
        v => !!v || 'El password es obligatorio',
      ],
      loginError: null,
      loging: false
    }
  },
  methods: {
    async loginUser() {
      if (this.$refs.loginForm.validate()) {

        this.loging = true; //disable button

        try {
          const response = await this.$api.login(this.form)
          
          const form = {
            access: response.access,
            refresh: response.refresh,
            remember: this.remember_me,
          }
  
          const userData = 
            await axios.get('app/user_info', {
              baseURL: process.env.VUE_APP_API_URL,
              headers: {
                'Authorization': `Bearer ${response.access}`,
              }
            })
          this.loging = false; //enable button
          form.user = userData.data
          
  
  
          this.$store.dispatch('session/setSessionData', form)
  
          form.user.role === 2
            ? this.$router.push('/messages')
            : this.$router.push('/stats')
          
        } catch (error) {
          console.log(error);
          this.loging = false; //enable button
          if (error?.response?.status === 401) {
            this.loginError = "El usuario o la contraseña son incorrectos"
          } else {
            this.loginError = "Ocurrió un problema, vuelva a intentar más tarde"
          }
        }
      }
      
    },
  },
  setup() {
    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
